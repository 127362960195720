import { useEffect } from "react"
import { navigate } from "gatsby"

const Redirect = ({ pageContext }) => {
  useEffect(() => {
    navigate(pageContext.redirectTo)
  }, [pageContext.redirectTo])
  return null
}

export default Redirect
